import { Spin } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { showErrorToast } from 'src/components/toast/Toast';
import { TCommonGetDashboardParams, TGetDashboardResponse } from 'src/interfaces/common-interface';
import ResponseError from 'src/interfaces/error-response-interface';
import { RoutePaths } from 'src/routes/routes-constants';
import { getOwnerDashboard } from 'src/services/owner-service';
import { toggleMessageOfTheDay } from 'src/services/auth-service';
import { getTheMessageOfTheDay } from 'src/services/motd-service';
import { getTrialTime } from 'src/services/payment-service';
import { getPractitionerDashboard } from 'src/services/practitioner-service';
import { TRootState } from 'src/stores';
import { getCurrentTimezone } from 'src/utils/common-utils';
import { EUserType } from 'src/variables/enum-variables';
import { EProfileTabKey } from '../profile/profile-page-constants';
import Dashboard from './components/Dashboard';
import ExpireTrialModal from './components/ExpireTrialModal';
import MessageOfTheDay from './components/MessageOfTheDay';
import './Home.scss';
import { TMessageOfTheDay } from 'src/interfaces/motd-interface';

const Home = () => {
  const navigate = useNavigate();
  //const motd = useSelector((state: TRootState) => state.user.motd);
  const userId = useSelector((state: TRootState) => state.user.id);
  const profile = useSelector((state: TRootState) => state.user.profile);
  const isOwnerRole = profile?.role && profile?.role === EUserType.OWNER;
  const requestDashboardParams: TCommonGetDashboardParams = {
    timezome: getCurrentTimezone(),
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openExpireTrialModal, setOpenExpireTrialModal] = useState<boolean>(false);
  const [openMessageOfTheDay, setOpenMessageOfTheDay] = useState<boolean>(false);
  const [messageOfTheDay, setMessageOfTheDay] = useState<TMessageOfTheDay>();
  const [count, setCount] = useState<number>(0);
  const [dashboardDetail, setDashboardDetail] = useState<TGetDashboardResponse>();

  const fetchMotdStatus = async ()=> {
    try {
      const messageOfTheDay = await getTheMessageOfTheDay();
      setMessageOfTheDay(messageOfTheDay);
    } catch (error) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
    }
  }

  const fetchTrialTime = async () => {
    try {
      const trial = await getTrialTime();
      if (trial.trialEnd) {
        const remainingTrialTime = dayjs(trial.trialEnd).diff(dayjs(), 'd') + 1;

        if (remainingTrialTime <= 3) {
          setCount(remainingTrialTime);
          setOpenExpireTrialModal(true);
        }
      }
    } catch (error) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
    }
  };

  const handleSubmitMessageOfTheDayModal = async () => {
    await toggleMessageOfTheDay({ id: userId, motd: false});
    setOpenMessageOfTheDay(false);
  }

  const handleSubmitExpireTrialModal = () => {
    navigate(RoutePaths.PROFILE, { state: { activeTab: EProfileTabKey.SUBSCRIPTION } });
  };

  const fetchOwnerDashboard = useCallback(async () => {
    try {
      const response = await getOwnerDashboard(requestDashboardParams);
      setDashboardDetail(response);
    } catch (error) {
      showErrorToast('Get owner dashboard faield!');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchPractitionerDashboard = useCallback(async () => {
    try {
      const response = await getPractitionerDashboard(requestDashboardParams);
      setDashboardDetail(response);
    } catch (error) {
      showErrorToast('Get practitioner dashboard failed!');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  /*useEffect(() => {
    fetchMotdStatus();
    if (motd === true) {
      setOpenMessageOfTheDay(motd);
    }
  }, [motd]);*/

  useEffect(() => {
    if (profile?.role === EUserType.OWNER || profile?.role === EUserType.SOLO_PRACTITIONER) fetchTrialTime();
  }, [profile]);

  useEffect(() => {
    if (isOwnerRole !== undefined) {
      isOwnerRole ? fetchOwnerDashboard() : fetchPractitionerDashboard();
    }
  }, [isOwnerRole]);

  const options = { 
    month: 'long', // Full month name (e.g., January)
    day: 'numeric', // Numeric day (e.g., 1, 2, 3)
    year: 'numeric' // Full year (e.g., 2023)
  } as Intl.DateTimeFormatOptions;
  

  return (
    <div className="Home">
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Dashboard dashboardDetail={dashboardDetail as TGetDashboardResponse} />
          <ExpireTrialModal
            open={openExpireTrialModal}
            onCancel={() => setOpenExpireTrialModal(false)}
            handleSubmit={handleSubmitExpireTrialModal}
            count={count}
          />
          { messageOfTheDay && (
            <MessageOfTheDay
            open={openMessageOfTheDay}
            onCancel={handleSubmitMessageOfTheDayModal}
            handleSubmit={handleSubmitMessageOfTheDayModal}
            motdTitle={`ANTSA ${new Date(messageOfTheDay?.createdAt).toLocaleDateString('en-US', options)} Version  `}
            patchNotes={messageOfTheDay.patchNotes}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Home;
