import { Modal, ModalProps } from 'antd';
import Button from 'src/components/button';
import { BaseText } from 'src/components/typography';
import ReactMarkdown from 'react-markdown'

interface IMessageOfTheDayModalProps extends ModalProps {
  handleSubmit: () => void;
  motdTitle: string;
  patchNotes: string;
}

const MessageOfTheDay = ({ handleSubmit, motdTitle, patchNotes, ...rest }: IMessageOfTheDayModalProps) => {
  return (
    <Modal
      width={1080}
      footer={null}
      title={
        <BaseText type="title" textAlign="left">
          {motdTitle}
        </BaseText>
      }
      bodyStyle={{ display: 'flex', flexDirection: 'column', gap: 16 }}
      centered
      {...rest}
    >
      <BaseText type="body1" textAlign="left">
        <ReactMarkdown>
          {patchNotes}
        </ReactMarkdown>
      </BaseText>
      <Button type="primary" onClick  ={handleSubmit}>
        Continue
      </Button>
    </Modal>
  );
};

export default MessageOfTheDay;
