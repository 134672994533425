import { AxiosResponse } from 'axios';

import { ApiClient } from './api-client';
import { TDass, TTrackMood } from 'src/interfaces/clients-interface';



interface TMoodStreakResponse {
  count: number;
  flag: number;
}

export const getTrackMoodByDay = async (clientId: string, fromAt: string, toAt: string) => {
  const response: AxiosResponse<TTrackMood[]> = await ApiClient.get(
    `/api/v1/practitioners/client-management/${clientId}/mood-tracker?fromAt=${fromAt}&toAt=${toAt}`,
  );
  return response.data;
};

export const getAverageTimeBetweenUpdates = async (clientId: string) => {
  const response: AxiosResponse<number> = await ApiClient.get(
    `/api/v1/practitioners/client-management/${clientId}/mood-statistics/average-time-between-updates`,
  );
  return response.data;
};

export const getAverageMoodByFlag = async (clientId: string) => {
  const response: AxiosResponse<TMoodStreakResponse> = await ApiClient.get(
    `/api/v1/practitioners/client-management/${clientId}/mood-statistics/average-mood-by-flag`,
  );
  return response.data;
};

export const getLongestMoodStreakByFlag = async (clientId: string) => {
  const response: AxiosResponse<TMoodStreakResponse> = await ApiClient.get(
    `/api/v1/practitioners/client-management/${clientId}/mood-statistics/longest-mood-streak-by-flag`,
  );
  return response.data;
};

export const getMoodChangeRate = async (clientId: string, period: string) => {
  const response: AxiosResponse<number> = await ApiClient.get(
    `/api/v1/practitioners/client-management/${clientId}/mood-statistics/mood-change-rate?period=${period}`,
  );
  return response.data;
};


export const getTrackDass = async (clientId: string, type: string) => {
  const response: AxiosResponse<TDass> = await ApiClient.get(
    `/api/v1/practitioners/client-management/${clientId}/dass-tracker?type=${type}`,
  );
  return response.data;
};
