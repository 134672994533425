import { Space, Input, Tag } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState, ChangeEvent, useRef } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from 'src/components/button';
import { showErrorToast, showSuccessToast } from 'src/components/toast/Toast';
import { BaseText } from 'src/components/typography';
import { AssignHomeworkTopic, HomeworkTasksColumn } from 'src/containers/homework';
import DragDropTasksColumn from 'src/containers/homework/DragDropTasksColumn';
import {
    ECategoryType,
    EHomeworkType,
    TAssignHomeworkRequest,
    TAssignHomeworkResponse,
    TClient,
    TGetTasksByTopicParams,
    THomework,
    TTaskItemData,
} from 'src/interfaces/clients-interface';
import { TGetHomeworkTopicsParams, TGetHomeworkTopicsResponse, THomeworkTopic } from 'src/interfaces/homework-topics';
import HomeWorkDetail from 'src/pages/homework/components/homework-detail/HomeWorkDetail';
import TimePeriod from 'src/pages/homework/components/time-period/TimePeriod';
import { TTimePeriodFormValues } from 'src/pages/homework/components/time-period/time-period-types';
import { TRootState, useAppDispatch } from 'src/stores';
import { EHomeworkTopicsAction, assignHomeworkAction, getTasksByTopicAction } from 'src/stores/homework-topics';
import { getHomeworkTopicsByTypeAction, loadMoreTasksByTopicAction } from 'src/stores/homework-topics/actions';
import { getCurrentTimezone } from 'src/utils/common-utils';
import { SEPARATE_TYPE_HOMEWORK } from 'src/variables/client';
import { DEFAULT_GET_LIST_PARAMS, DEFAULT_PAGE, EProfileStatus } from 'src/variables/common';
import { ESortType, ETaskType } from 'src/variables/enum-variables';
import { EDragDropColumnId } from '../clients/client-details-page-constants';
import './BulkAssignHomework.scss'; import { PlusOutlined } from '@ant-design/icons';
import { dischargeClient, EClientsAction, getClients, inviteClient } from 'src/stores/clients';
import { TCommonGetListParams } from 'src/interfaces/common-interface';

interface Client {
    id: string;
    name: string;
    email: string;
    avatar: string;
    status: string;
}

// Helper function to determine the color based on the client's status
const getStatusColor = (status: EProfileStatus) => {
    switch (status) {
        case 'PENDING':
            return 'gold';
        case 'INACTIVE':
            return 'red';
        case 'ACTIVE':
            return 'green';
        case 'DISCHARGED':
            return 'gray';
        default:
            return 'default';
    }
};

const DEFAULT_TOPIC_PAGE_SIZE = 1000;
const DEFAULT_HOMEWORK_PAGE_SIZE = 14;

const DEFAULT_HOMEWORK_TOPIC_PARAMS: TGetHomeworkTopicsParams = {
    ...DEFAULT_GET_LIST_PARAMS,
    sortBy: 'name',
    sortType: ESortType.ASC,
    size: DEFAULT_TOPIC_PAGE_SIZE,
    homeworkType: EHomeworkType.ACTIVITIES,
};

const DEFAULT_HOMEWORK_BY_TOPIC_PARAMS: TGetTasksByTopicParams = {
    ...DEFAULT_GET_LIST_PARAMS,
    sortBy: 'title',
    size: DEFAULT_HOMEWORK_PAGE_SIZE,
    sortType: ESortType.ASC,
    homeworkTopicId: '',
};

type TSelectedHomeworkList = {
    formValues: TTimePeriodFormValues;
    data: TTaskItemData;
    homework?: THomework;
};

interface IProps {
    onCancel: () => void;
    onAssignSuccess: () => void;
    isBlockNavigateRef: React.MutableRefObject<boolean>;
}

const BulkAssignHomework = ({ onCancel, onAssignSuccess, isBlockNavigateRef }: IProps) => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const [clientsParams, setClientsParams] = useState<TCommonGetListParams>(DEFAULT_GET_LIST_PARAMS);

    const { clientList } = useSelector((state: TRootState) => state.clients);

    const [selectedClients, setSelectedClients] = useState<TClient[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [searchResults, setSearchResults] = useState<TClient[]>(clientList.data);
    const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
    const searchContainerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [hasMore, setHasMore] = useState(true);
    const [fromSearch, setFromSearch] = useState(true);


    const selectAllActiveClients = () => {
        setSelectedClients([]);
        setIsSearchActive(true);
        
        const newParams = {
            ...clientsParams,
            page: DEFAULT_PAGE,
            keyword: '',
            status: 'ACTIVE',
        };
    
        setClientsParams(newParams);
    };

    // Filter clients by name and exclude already selected clients by ID
    const updateSearchResults = (value: string) => {
        const filteredClients = clientList.data.filter(client =>
            `${client.firstName} ${client.lastName}`.toLowerCase().includes(value.toLowerCase()) &&
            !selectedClients.some(selected => selected.id === client.id)
        ).sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`));  // Sort results
        setSearchResults(filteredClients);
    };



    const handleSearchClient = (keyword: string) => {
        const newParams = {
        ...clientsParams,
        page: DEFAULT_PAGE,
        keyword,
        status: 'ACTIVE,INACTIVE,PENDING',
        };

        setClientsParams(newParams);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;
        setInputValue(value);
        //updateSearchResults(value);
        handleSearchClient(value)
    };

    // Select a client by ID instead of name
    const handleInputConfirm = (clientId?: string): void => {
        const selectedId = clientId || searchResults.find(client => `${client.firstName} ${client.lastName}` === inputValue)?.id;
        if (selectedId) {
            const newClient = searchResults.find(client => client.id === selectedId);
            if (newClient) {
                const updatedSelectedClients = [...selectedClients, newClient];
                setSelectedClients(updatedSelectedClients);

                // Remove the selected client from search results
                const updatedSearchResults = searchResults.filter(client => client.id !== selectedId);
                setSearchResults(updatedSearchResults);
            }
        }
        setInputValue('');
    };

    const handleClose = (removedClientId: string): void => {
        // First find the client that needs to be removed
        const removedClient = selectedClients.find(client => client.id === removedClientId);
    
        // Then filter out this client from the selected clients
        const newSelectedClients = selectedClients.filter(client => client.id !== removedClientId);
        setSelectedClients(newSelectedClients);
    
        // If the removed client exists, add it back to the search results
        if (removedClient) {
            setSearchResults(prevResults => [...prevResults, removedClient].sort((a, b) => 
                a.id.localeCompare(b.id)  // Sorting by id to avoid duplicates based on name
            ));
        } else {
            updateSearchResults(inputValue); // Update to ensure list is still sorted and filtered
        }
    };

    const { activityTopics, homework, loadingState } = useSelector((state: TRootState) => ({
        activityTopics: state.homeworkTopics.activityTopics,
        homework: state.homeworkTopics.homework,
        loadingState: state.loading,
    }));

    const [selectedTopic, setSelectedTopic] = useState<THomeworkTopic>(activityTopics.data?.[0]);
    const [searchKeywordHomework, setSearchKeywordHomework] = useState<string>('');
    const [setupScheduleModalState, setSetupScheduleModalState] = useState<{
        open: boolean;
        selectedTask?: THomework;
        formValues?: TTimePeriodFormValues;
    }>({
        open: false,
        selectedTask: undefined,
    });
    const [selectedHomeworkList, setSelectedHomeworkList] = useState<{ [homeworkId: string]: TSelectedHomeworkList }>({});
    const [homeworkDetailModalState, setHomeworkDetailModalState] = useState<{ open: boolean; homework?: TTaskItemData }>(
        {
            open: false,
            homework: undefined,
        },
    );

    const isSeparateHomeworkList = SEPARATE_TYPE_HOMEWORK.includes(selectedTopic?.homeworkType);
    const allHomework = homework[selectedTopic?.id];
    const ownerHomework = homework[`${selectedTopic?.id}-${ETaskType.OWNER}`];
    const generalHomework = homework[`${selectedTopic?.id}-${ETaskType.GENERAL_TASK}`];
    const combinedHomeworkData = isSeparateHomeworkList
        ? [...(ownerHomework?.data ?? []), ...(generalHomework?.data ?? [])]
        : [];
    const currentHomeworkData = isSeparateHomeworkList ? combinedHomeworkData : allHomework?.data;

    const handleClickTopic = (item: THomeworkTopic) => {
        setSelectedTopic(item);
        setSearchKeywordHomework('');
    };

    const handleDragEnd = (result: DropResult) => {
        const { draggableId, destination } = result;
        const destinationColumnId = destination?.droppableId;
        const droppedToAssignedColumn = destinationColumnId === EDragDropColumnId.ASSIGNED;

        if (!droppedToAssignedColumn) return;

        const selectedTask = currentHomeworkData?.find((item) => item.id === draggableId);

        setSetupScheduleModalState({
            open: true,
            selectedTask: selectedTask,
        });
    };

    const handleCloseTimePeriodModal = () => {
        setSetupScheduleModalState({ open: false, selectedTask: undefined });
    };

    const getTopicsFirstTime = async () => {
        dispatch(getClients(clientsParams));

        const response = await dispatch(getHomeworkTopicsByTypeAction(DEFAULT_HOMEWORK_TOPIC_PARAMS));
        setSelectedTopic((response.payload as TGetHomeworkTopicsResponse).data?.[0]);
        dispatch(
            getHomeworkTopicsByTypeAction({ ...DEFAULT_HOMEWORK_TOPIC_PARAMS, homeworkType: EHomeworkType.QUESTIONNAIRES }),
        );
        dispatch(
            getHomeworkTopicsByTypeAction({ ...DEFAULT_HOMEWORK_TOPIC_PARAMS, homeworkType: EHomeworkType.WRITTEN_TASKS }),
        );
        dispatch(
            getHomeworkTopicsByTypeAction({ ...DEFAULT_HOMEWORK_TOPIC_PARAMS, homeworkType: EHomeworkType.AI }),
        );
        dispatch(getHomeworkTopicsByTypeAction({ ...DEFAULT_HOMEWORK_TOPIC_PARAMS, homeworkType: EHomeworkType.VIDEOS }));
        
    };

    const handleSubmitTimePeriod = (values: TTimePeriodFormValues) => {
        const homeworkDetail = setupScheduleModalState.selectedTask;
        const formattedData: TSelectedHomeworkList = {
            formValues: values,
            homework: homeworkDetail,
            data: {
                id: homeworkDetail?.id ?? '',
                title: homeworkDetail?.title ?? '',
                assigned: true,
                repeat: !!values.repeatEvery,
                startDate: values.startDate,
                endDate: values.endDate,
                type: homeworkDetail?.type,
                videoUrl: homeworkDetail?.videoLink ?? '',
                description: homeworkDetail?.description,
                showPreviewImg: true,
                timezone: homeworkDetail?.timezone ?? '',
                createdAt: homeworkDetail?.createdAt,
                updatedAt: homeworkDetail?.updatedAt,
                category: homeworkDetail?.category ?? ECategoryType.ACTION,
            },
        };
        setSelectedHomeworkList({
            ...selectedHomeworkList,
            [setupScheduleModalState.selectedTask?.id ?? '']: formattedData,
        });
    };

    const handleRemoveAssignedHomework = (item: TTaskItemData) => {
        const newSelectedHomeworkList = { ...selectedHomeworkList };

        delete newSelectedHomeworkList[item.id];
        setSelectedHomeworkList(newSelectedHomeworkList);
    };

    const loadMoreTasksByTopic = (nextPage: number, taskType?: EDragDropColumnId) => {
        const newParams = {
            ...DEFAULT_HOMEWORK_BY_TOPIC_PARAMS,
            page: nextPage,
            keyword: searchKeywordHomework || '',
            homeworkTopicId: selectedTopic?.id,
            ...(taskType && { isOwnTask: taskType === EDragDropColumnId.OWN }),
        };

        dispatch(loadMoreTasksByTopicAction(newParams));
    };

    const debounceSearch = useCallback(
        debounce((searchKey: string, topicId?: string) => {
            const newParams = {
                page: 1,
                size: DEFAULT_HOMEWORK_PAGE_SIZE,
                keyword: searchKey,
                homeworkTopicId: topicId ?? '',
            };

            dispatch(getTasksByTopicAction(newParams));
        }, 500),
        [],
    );

    const handleSearchHomework = (keyword: string) => {
        setSearchKeywordHomework(keyword);
        debounceSearch(keyword, selectedTopic?.id);
    };

    const handleClickHomework = (item: TTaskItemData) => {
        setHomeworkDetailModalState({ open: true, homework: item });
    };

    const handleCloseHomeworkDetailModal = () => {
        setHomeworkDetailModalState({ ...homeworkDetailModalState, open: false });
    };

    const handleAssign = async () => {
        const assignHomeworkSuccessIds = [];
    
        for (const client of selectedClients) {
            const assignHomework = Object.entries(selectedHomeworkList).map(([key, values]) => {
                const data = {
                    homeworkId: key,
                    frequency: values.formValues.frequency,
                    repeatEvery: values.formValues.repeatEvery,
                    repeatDuration: values.formValues.repeatDuration,
                    dayOfWeek: values.formValues.dayOfWeek,
                    startDate: values.formValues.startDate.toISOString(),
                    endType: {
                        expiredDate: values.formValues.endDate?.toISOString(),
                        afterTimes: Number(values.formValues.endAfter),
                    },
                    reminderAt: {
                        time: dayjs(values.formValues.reminderTime).format('hh:mm'),
                        period: dayjs(values.formValues.reminderTime).format('A'),
                    },
                    timezone: values.formValues.timezone,
                    ...(values.homework?.type === EHomeworkType.AI ? {
                        persona: values.formValues.persona,
                        optInSharing: values.formValues.optInSharing,
                    } : {})
                };
    
                if (data.endType.expiredDate) {
                    return { ...data, endType: { expiredDate: data.endType.expiredDate } };
                } else {
                    return { ...data, endType: { afterTimes: data.endType.afterTimes } };
                }
            });
    
            const bodyRequest: TAssignHomeworkRequest = {
                clientId: client.clientId,
                listAssignHomework: assignHomework,
                timezone: getCurrentTimezone(),
            };
            
            try {
                const { payload: response } = await dispatch(assignHomeworkAction(bodyRequest));
                if ((response as TAssignHomeworkResponse)?.homeworkAssignIds?.length) {
                    assignHomeworkSuccessIds.push(client.clientId);
                }
            } catch (error) {
                console.error('Error assigning homework to client', client.id, error);
            }
        }
    
        if (assignHomeworkSuccessIds.length > 0) {
            showSuccessToast(`Homework tasks have been successfully assigned to ${assignHomeworkSuccessIds.length} client${assignHomeworkSuccessIds.length > 1 ? 's' : ''}!`);
            onCancel();
            onAssignSuccess();
            isBlockNavigateRef.current = false;
        } else {
            showErrorToast('Failed to assign homework tasks to any clients.');
        }
    };
    

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
            setIsSearchActive(false);
        }
    }, []);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    const getClientList = (params: TCommonGetListParams) => {
        dispatch(getClients(params));
    };

    useEffect(() => {
        getClientList(clientsParams);
      }, [clientsParams]);

    const fetchMoreData = useCallback(() => {
        setClientsParams((prevParams) => ({
            ...prevParams,
            page: prevParams.page + 1,
        }));
    }, []);

    const handleScroll = useCallback(() => {
        if (!containerRef.current) return;
    
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        if (scrollHeight - scrollTop <= clientHeight + 5) {
            if (hasMore) fetchMoreData();
        }
    }, [hasMore, fetchMoreData]);

    useEffect(() => {
        const div = containerRef.current;
        if (div) {
          div.addEventListener('scroll', handleScroll);
        }
      
        return () => {
          if (div) {
            div.removeEventListener('scroll', handleScroll);
          }
        };
      }, [containerRef.current, handleScroll]);

    useEffect(() => {
        if (isBlockNavigateRef) {
            isBlockNavigateRef.current = !!Object.keys(selectedHomeworkList).length;
        }
    }, [selectedHomeworkList]);

    useEffect(() => {
        setHasMore(clientList.currentPage < clientList.totalPage);
    }, [clientList.currentPage, clientList.totalPage]);
    
    useEffect(() => {
        const selectedClientIds = new Set(selectedClients.map(client => client.id));
        if (clientList.currentPage === 1) {
            // Filter out selected clients
            const filteredClients = clientList.data.filter(client => !selectedClientIds.has(client.id));
            setSearchResults(filteredClients);
        } else {
            setSearchResults(prevClients => {
                const existingIds = new Set(prevClients.map(client => client.id));
                const newUniqueClients = clientList.data.filter(
                    client => !existingIds.has(client.id) && !selectedClientIds.has(client.id)
                );
                return [...prevClients, ...newUniqueClients];
            });
        }
    }, [clientList, selectedClients]);
    
    useEffect(() => {
        if (selectedTopic?.id) {
            if (isSeparateHomeworkList) {
                dispatch(
                    getTasksByTopicAction({
                        ...DEFAULT_HOMEWORK_BY_TOPIC_PARAMS,
                        isOwnTask: false,
                        homeworkTopicId: selectedTopic.id,
                    }),
                );

                dispatch(
                    getTasksByTopicAction({
                        ...DEFAULT_HOMEWORK_BY_TOPIC_PARAMS,
                        isOwnTask: true,
                        homeworkTopicId: selectedTopic.id,
                    }),
                );

                return;
            }

            dispatch(
                getTasksByTopicAction({
                    ...DEFAULT_HOMEWORK_BY_TOPIC_PARAMS,
                    homeworkTopicId: selectedTopic.id,
                }),
            );
        }
    }, [selectedTopic]);

    useEffect(() => {
        getTopicsFirstTime();

        return () => {
            // This cleanup function is called when the component unmounts
            setSelectedClients([]);  // Clears selected clients
            setSearchResults([]);    // Clears search results
        };
    }, []);

    return (
        <>
            <div className="header">
                <div ref={searchContainerRef} className="clientSelection">
                    <div className="selectedClients">
                        {selectedClients.map(client => (
                            <Tag
                                key={client.id}
                                closable
                                onClose={() => handleClose(client.id)}
                                color={getStatusColor(client.status)}
                            >
                                {client.firstName} {client.lastName}
                            </Tag>
                        ))}
                        <Input
                            placeholder={"Search clients..."}
                            value={inputValue}
                            onChange={handleInputChange}
                            onFocus={() => setIsSearchActive(true)}
                        />
                        <Button className="select-active-btn" onClick={selectAllActiveClients}>
                            Find All Active Clients
                        </Button>
                    </div>
                    {isSearchActive && (
                        <div ref={containerRef} className="searchResults">
                            {searchResults.map(client => (
                                <div
                                    key={client.id}
                                    onClick={() => handleInputConfirm(client.id)}
                                    className="searchResultItem"
                                >
                                    {client.firstName} {client.lastName}
                                </div>
                            ))}
                            {hasMore && (
                                <div
                                    onClick={fetchMoreData}
                                    style={{
                                        color: '#48abe2',
                                        cursor: 'pointer',
                                        marginTop: '5px',
                                        marginLeft: '10px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Load More
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="AssignHomework">
                <DragDropContext onDragEnd={handleDragEnd}>
                    <AssignHomeworkTopic
                        className="AssignHomework__topic"
                        selectedTopic={selectedTopic}
                        onClickTopic={handleClickTopic}
                    />
                    <HomeworkTasksColumn
                        className="AssignHomework__tasks"
                        selectedTopic={selectedTopic}
                        searchValue={searchKeywordHomework}
                        selectedHomeworkIds={Object.keys(selectedHomeworkList) ?? []}
                        onLoadMore={loadMoreTasksByTopic}
                        onSearchHomework={handleSearchHomework}
                        onClickHomework={handleClickHomework}
                    />
                    <div className="AssignHomework__assigned">
                        <Space size={10}>
                            <BaseText type="title">Homework tasks to be assigned</BaseText>
                            <BaseText inline className="AssignHomework__assigned-total">
                                {Object.keys(selectedHomeworkList).length ?? 0}
                            </BaseText>
                        </Space>
                        <DragDropTasksColumn
                            items={Object.keys(selectedHomeworkList).map((key) => selectedHomeworkList[key].data) ?? []}
                            type="drop"
                            columnId={EDragDropColumnId.ASSIGNED}
                            className="AssignHomework__assigned-list"
                            onRemove={handleRemoveAssignedHomework}
                            onClick={(item) => {
                                setSetupScheduleModalState({
                                    open: true,
                                    selectedTask: selectedHomeworkList[item.id].homework,
                                    formValues: selectedHomeworkList[item.id].formValues,
                                });
                            }}
                        />
                        <div className="AssignHomework__assigned-actions">
                            <Button onClick={onCancel}>Cancel</Button>
                            <Button
                                type="primary"
                                loading={loadingState[EHomeworkTopicsAction.ASSIGN_HOMEWORK]}
                                onClick={handleAssign}
                                disabled={selectedClients.length === 0 || Object.keys(selectedHomeworkList).length === 0}
                                >
                                Assign
                            </Button>
                        </div>
                    </div>
                </DragDropContext>

                <TimePeriod {...setupScheduleModalState} onClose={handleCloseTimePeriodModal} onSubmit={handleSubmitTimePeriod} />

                <HomeWorkDetail
                    open={homeworkDetailModalState.open}
                    name={selectedTopic?.name}
                    title={homeworkDetailModalState.homework?.title ?? ''}
                    category={homeworkDetailModalState.homework?.category ?? ECategoryType.ACTION}
                    description={homeworkDetailModalState.homework?.description ?? ''}
                    videoUrl={homeworkDetailModalState.homework?.videoUrl ?? ''}
                    reminderAt={homeworkDetailModalState.homework?.remindAtFormat}
                    type={homeworkDetailModalState.homework?.type ?? EHomeworkType.ACTIVITIES}
                    onClose={handleCloseHomeworkDetailModal}
                />
            </div>
        </>
    );
};

export default BulkAssignHomework;
