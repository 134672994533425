import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import chartTrendline from 'chartjs-plugin-trendline';
import { useParams } from 'react-router-dom';
import TimePeriod from './TimePeriod';
import { Spin, Empty } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, chartTrendline);

import './RecentScore.scss';
import { BaseText } from 'src/components/typography';
import { ETimePeriodTracker } from 'src/variables/enum-variables';
import { TTrackMood } from 'src/interfaces/clients-interface';
import { 
  getTrackMoodByDay,
  getAverageTimeBetweenUpdates,
  getAverageMoodByFlag,
  getLongestMoodStreakByFlag,
  getMoodChangeRate
} from 'src/services/client-service';
import ResponseError from 'src/interfaces/error-response-interface';
import { showErrorToast } from 'src/components/toast/Toast';
import { TFilter, getFilterTitle } from '../util';

import { EmotionIcon } from 'src/assets/icons';
import { date } from 'yup';

interface MoodData {
    count: number;
    emoji: number;
    color: string;
    textColor: string;
  }

  interface DonutChartProps {
    data: MoodData[];
    radius: number;
  }

  interface FlagColorMapping {
    [key: number]: { color: string; textColor: string };
  }

  interface Score {
    id: string;
    flag: number;
    point: number;
    comment: string;
    createdAt: string;
  }

  
  function isWithinLast7Days(dateString: string): boolean {
    const createdAtDate = new Date(dateString);
    const currentDate = new Date();
    const sevenDaysAgo = currentDate.setDate(currentDate.getDate() - 7);
    return createdAtDate.getTime() >= sevenDaysAgo;
  }

  export function formatDate(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    };
    return date.toLocaleDateString(undefined, options);
  }
  
  export function formatTime(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return date.toLocaleTimeString(undefined, options);
  }

const RecentScore = () => {
  const params = useParams();
  const clientId = params?.clientId || '';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recentScores, setRecentScores] = useState<TTrackMood[]>([]);
  const [moodData, setMoodData] = useState<MoodData[]>([]);
  const [last7DayMoodData, setLast7DayMoodData] =useState<Score[]>([]);
  const [fromDate, setFromDate] =useState<string>();
  const [toDate, setToDate] =useState<string>();
  const [selectedEmoji, setSelectedEmoji] = useState<number | null>(null);
  const [selectedPath, setSelectedPath] = useState<number | null>(null);

  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);

  const [filter, setFilter] = useState<TFilter>({
    type: ETimePeriodTracker.SEVEN_DAY,
  });

  useEffect(() => {
    getChartData();
  }, [filter]);

  const toggleShowFilter = () => {
    setIsShowFilter(!isShowFilter);
  };

  const onFilter = (value: TFilter) => {
    setFilter(value);
    setIsShowFilter(false);
  };

  const getChartData = async () => {
    try {
      setIsLoading(true);

      let fromAt = dayjs().format('YYYY-MM-DD'),
        toAt = dayjs().format('YYYY-MM-DD');
      if (filter.type === ETimePeriodTracker.SEVEN_DAY) {
        fromAt = dayjs().subtract(6, 'd').format('YYYY-MM-DD');
      } else if (filter.type === ETimePeriodTracker.MONTH) {
        fromAt = dayjs().subtract(29, 'd').format('YYYY-MM-DD');
      } else {
        fromAt = dayjs(filter.startDate).format('YYYY-MM-DD');
        toAt = dayjs(filter.endDate).format('YYYY-MM-DD');
      }

      setToDate(toAt);
      setFromDate(fromAt);

      const res = await getTrackMoodByDay(clientId, fromAt, toAt);
      setRecentScores(res);
    } catch (e) {
      const { message } = e as ResponseError;
      showErrorToast(message);
    } finally {
      setIsLoading(false);
    }
  };

  const getDayName = (date: string): string => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const index = new Date(date).getUTCDay()
    return days[index];
  };
  
  useEffect(() => {
    //lol
    console.log(moodData);
  }, [moodData]);

  useEffect(() => {


  const flagColorMapping: FlagColorMapping = {
    [1]: { color: '#FF0000', textColor: 'white' },     // Angry - Red
    [2]: { color: '#0000FF', textColor: 'white' },     // Sad - Blue
    [3]: { color: '#FFFF00', textColor: 'black' },     // Neutral - Yellow
    [4]: { color: '#00FF00', textColor: 'black' },     // Smile - Light Green
    [5]: { color: '#66CC33', textColor: 'white' },     // Happy - Green
    [6]: { color: '#33CC33', textColor: 'white' },     // Grimacing Face - Orange
    [7]: { color: '#FFA500', textColor: 'white' },     // Fearful Face - Purple
    [8]: { color: '#800080', textColor: 'white' },     // Fearful Face - Purple
    [9]: { color: '#9933CC', textColor: 'white' },     // Fearful Face - Purple
    [10]: { color: '#CC99FF', textColor: 'black' },     // Fearful Face - Purple
    [11]: { color: '#808080', textColor: 'white' },     // Fearful Face - Purple
    [12]: { color: '#CCCCFF', textColor: 'black' },     // Fearful Face - Purple
    [13]: { color: '#990000', textColor: 'white' },     // Fearful Face - Purple
    [14]: { color: '#6666CC', textColor: 'white' },     // Fearful Face - Purple
  };

    // Count the occurrences of each flag value
    const flagCounts: { [key: number]: number } = {};
        recentScores.forEach(item => {
        const { flag } = item;
        flagCounts[flag] = (flagCounts[flag] || 0) + 1;
    });

    // Create the new array in the desired format
    const moodData = Object.entries(flagCounts).map(([flag, count]) => ({
      count,
      emoji: Number(flag),
      color: flagColorMapping[Number(flag)].color,
      textColor: flagColorMapping[Number(flag)].textColor,
    }));

    setMoodData(moodData);

    // Filter and keep only the scores from the last 7 days
    const last7DaysScores: Score[] = recentScores.filter((score) =>
      isWithinLast7Days(score.createdAt)
    );
    last7DaysScores.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    setLast7DayMoodData(last7DaysScores);

  }, [recentScores]);

    /* eslint-disable react/prop-types */
  const DonutChart: React.FC<DonutChartProps> = ({ data, radius }) => {
    const total = data.reduce((sum, { count }) => sum + count, 0);
    const transformAdjustment = total >= 10 ? 100 : 100;

    const handlePathClick = (index: number | null) => {
      setSelectedPath(index === selectedPath ? null : index);
    };

    if (data.length === 1 && data[0].count >= 1) {
      const { color } = data[0];
      return (
        <svg width={radius * 2} height={radius * 2}>
          <circle cx={radius} cy={radius} r={radius * 0.8} fill="transparent" stroke={color} strokeWidth={radius * 0.4} onClick={() => handlePathClick(0)}/>
          <g transform={`translate(${radius - transformAdjustment}, ${radius - 80})`}>
            <text style={{ fontSize: 20, fontWeight: 'bold' }} x={radius} y={radius - 12} textAnchor="middle">
              {total}
            </text>
          </g>
        </svg>
      );
    }
  
    return (
      <svg width={radius * 2} height={radius * 2}>
        {data.map((mood, index) => {
          const { count, color } = mood;
          const percentage = count / total;
          const angle = percentage * 360;
          const startAngle = index === 0 ? -90 : data.slice(0, index).reduce((acc, mood) => acc + (mood.count / total) * 360, -90);
          const endAngle = startAngle + angle;
          const startAngleRad = (startAngle * Math.PI) / 180;
          const endAngleRad = (endAngle * Math.PI) / 180;
          const x1 = radius + radius * Math.cos(startAngleRad);
          const y1 = radius + radius * Math.sin(startAngleRad);
          const x2 = radius + radius * Math.cos(endAngleRad);
          const y2 = radius + radius * Math.sin(endAngleRad);
          const largeArcFlag = angle >= 180 ? 1 : 0;
          const path = `M ${x1},${y1} A ${radius},${radius} 0 ${largeArcFlag} 1 ${x2},${y2} L ${radius},${radius}`;
  
          return <path strokeWidth={index === data.length - 1 ? 3 : 4}  key={mood.emoji} d={path} fill={selectedPath === null ? color : selectedPath === mood.emoji ? color : '#E0E0E0'} onClick={() => handlePathClick(mood.emoji)} />;
        })}
        <circle cx={radius} cy={radius} r={radius * 0.6} fill="white" onClick={() => handlePathClick(null)}/>
        <g transform={`translate(${radius - transformAdjustment}, ${radius - 80})`}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }} x={radius} y={radius - 12} textAnchor="middle">
            {selectedPath !== null ? data.find(mood => mood.emoji === selectedPath)?.count : total}
          </text>
        </g>
      </svg>
    );
  };
  return (
    <div className="RecentScore">
      <div className="RecentScore__header">
        <BaseText type="title">Snapshot</BaseText>
        <div className="RecentScore__filter" onClick={toggleShowFilter}>
          <BaseText type="caption">{getFilterTitle(filter.type)}</BaseText>
        </div>
      </div>
      <div className="RecentScore__item">
        {isLoading ? (
          <div className="RecentScore__loading">
            <Spin tip="Loading" size="small">
              <div className="content" />
            </Spin>
          </div>
        ) : (
          <>
          {moodData.length ? (
          <>
            <div className="RecentScore__header" style={{"justifyContent":"center"}}>
              <BaseText type="caption">{`${fromDate} - ${toDate}`}</BaseText>
            </div>
            <div className="RecentScore__trackedContainer">
              <DonutChart data={moodData} radius={100} />

            </div>
                          
            <div className="RecentScore__stackedRow">
                {moodData.map((mood, index) => (
                  <div key={index} className="RecentScore__moodContainer">
                    <div
                      className={`EmotionIcon ${
                        selectedPath === mood.emoji ? 'selected-emotion-icon' : ''
                      }`} 
                      onClick={() => setSelectedPath(mood.emoji === selectedPath ? null : mood.emoji)
                      }
                    >
                      <EmotionIcon
                        rate={mood.emoji}
                        size={selectedPath === mood.emoji ? 30 : 34}
                        />
                    </div>
                    
                  </div>
                ))}
              </div>
              {selectedPath !== null && (
                <div className="RecentScore__selectedScores">
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {recentScores
                      .filter((score) => score.flag === selectedPath)
                      .map((score) => (
                        <li
                          className="SelectedScore__item"
                          key={score.id}
                        >
                          <div className="SelectedScore__datetime">
                            <div className="SelectedScore__date">
                              {formatDate(score.createdAt)}
                            </div>
                            <div className="SelectedScore__time">
                              {formatTime(score.createdAt)}
                            </div>
                          </div>
                          <div className={`SelectedScore__comment ${score.comment === '' ? 'light-grey-text' : ''}`}>
                            {score.comment || 'No comment available'}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              )}


          </>
          ) : <Empty description="No data" />}

          {isShowFilter ? (
            <TimePeriod isOpen={true} onClose={toggleShowFilter} value={filter} onSubmit={onFilter} />
          ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default RecentScore;
