import { PropsWithChildren, CSSProperties } from 'react'; // Import CSSProperties for style typing
import { BaseText } from '../typography';

import './FlexibleCommonContent.scss';
import { ChevronLeftIcon } from 'src/assets/icons';

interface IProps extends PropsWithChildren {
  className?: string;
  title: React.ReactNode;
  action?: React.ReactNode;
  haveBackButton?: boolean;
  onClickToBackButton?: () => void;
  styles?: CSSProperties;
}

const FlexibleCommonContent = ({
  className,
  title,
  action,
  haveBackButton,
  onClickToBackButton,
  children,
}: IProps) => {
  return (
    <div className={`FlexibleCommonContent ${className ?? ''}`}>
      <div className="FlexibleCommonContent__header">
        <div className="FlexibleCommonContent__header-title">
          {haveBackButton && (
            <ChevronLeftIcon className="FlexibleCommonContent__header-backBtn" onClick={() => onClickToBackButton?.()} />
          )}
          {typeof title === 'string' ? <BaseText type="display1">{title}</BaseText> : title}
        </div>
        {action && <div className="FlexibleCommonContent__header-action">{action}</div>}
      </div>
      <div className="FlexibleCommonContent__non-flex-content">{children}</div>
    </div>
  );
};

export default FlexibleCommonContent;
