import Avatar from 'src/components/avatar';
import { BaseText } from 'src/components/typography';
import { TDownloadFileMessageRequest, TRoomMessage } from 'src/interfaces/chat-interface';
import { getItem } from 'src/utils/storage-utils';
import { EUserProfile } from 'src/variables/storage';
import './SingleMessage.scss';
import QuickMessageFiles from './QuickMessageFiles';
import { TFile } from 'src/interfaces/common-interface';
import { formatMessageTimeLine } from 'src/utils/common-utils';
import { ColorMappingClassification } from 'src/interfaces/open-ai-interface';
import { PredictedLabel } from 'src/interfaces/open-ai-interface';

interface IProps {
  data: TRoomMessage;
  nextMessage?: TRoomMessage;
  onPreviewFiles: (files: TFile[], fileIndex?: number) => void;
  onDownloadFile: (params: TDownloadFileMessageRequest, fileName?: string) => void;
}

const SingleMessage = ({ data, nextMessage, onPreviewFiles, onDownloadFile }: IProps) => {
  const currentProfileId = getItem(EUserProfile.PROFILE_ID);
  const senderId = (data?.senderProfile ?? data?.senderClient)?.id;
  const isMyMessage = senderId === currentProfileId;
  const nextMessageProfile = nextMessage?.senderClient ?? nextMessage?.senderProfile;
  const isShowAvatar = !isMyMessage && (!nextMessage || nextMessageProfile?.id === currentProfileId);

  const handleDownloadFile = (fileId: string, fileName?: string) => {
    onDownloadFile(
      {
        messageId: data.id,
        roomId: data.roomId,
        fileId,
      },
      fileName,
    );
  };

  return (
    <>
      <div className={`SingleMessage ${isMyMessage ? 'own' : 'partner'} `}>
        {isMyMessage && (
          <>
            {!!data.files.length && (
              <QuickMessageFiles
                files={data.files}
                className={`SingleMessage__quickFiles ${isMyMessage ? 'own' : 'partner'}`}
                onPreviewFiles={onPreviewFiles}
                onDownloadFile={handleDownloadFile}
              />
            )}
            {data.content && (
              <BaseText className="SingleMessage__text own" type="body1" dangerouslyText={data.content} key={data.id} />
            )}
          </>
        )}
        {!isMyMessage && (
          <div className={`SingleMessage__partner ${isShowAvatar ? 'showAvt' : 'hideAvt'}`} key={data.id}>
            {isShowAvatar && <Avatar className="SingleMessage__partner-avatar" src={data.senderClient?.avatar} />}
            <div className={`SingleMessage__partner-details`}>
              {!!data.files.length && (
                <QuickMessageFiles
                  files={data.files}
                  className={`SingleMessage__quickFiles ${isMyMessage ? 'own' : 'partner'}`}
                  onPreviewFiles={onPreviewFiles}
                  onDownloadFile={handleDownloadFile}
                />
              )}
              { data?.classification && data?.classification !== PredictedLabel.NotApplicable && data?.classification !== PredictedLabel.NonDistressing  ? (
                <div 
                style={{
                  backgroundColor: ColorMappingClassification[data?.classification]?.backgroundColor,
                  color: ColorMappingClassification[data?.classification]?.fontColor,
                  marginTop:10,
                  paddingLeft: 5,
                  paddingRight: 5,
                  borderRadius:5,
                  display: 'inline-block',
                  width: "100%"
                }}>
                  <BaseText  type="body1" dangerouslyText={data.content} 
                    style={{
                      fontWeight: 'bold',
                      textDecoration: `underline dotted ${ColorMappingClassification[data?.classification]?.fontColor}`,
                      padding: '8px 16px',
                      marginBottom: '6px'
                    }}
                  />
                    <BaseText
                      className={`ActivityCard__commonContent`}
                      type="body1"
                    >
                    {`${String.fromCodePoint(0x26A0)} This message has been detected as ${data?.classification}`}
                  </BaseText>
                </div>
              ): (
                <BaseText className={`SingleMessage__text`} type="body1" dangerouslyText={data.content}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {data.isShowTime && <p className="SingleMessage__timeline">{formatMessageTimeLine(data.createdAt)}</p>}
    </>
  );
};

export default SingleMessage;
