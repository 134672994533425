export enum EClientsAction {
  GET_CLIENTS = 'GET_CLIENTS',
  INVITE_CLIENT = 'INVITE_CLIENT',
  DISCHARGE_CLIENT = 'DISCHARGE_CLIENT',
  GET_CLIENT_HOMEWORK = 'GET_CLIENT_HOMEWORK',
  LOAD_MORE_CLIENT_HOMEWORK = 'LOAD_MORE_CLIENT_HOMEWORK',
  GET_TOTAL_ASSIGNED_HOMEWORK = 'GET_TOTAL_ASSIGNED_HOMEWORK',
  REMOVE_ASSIGNED_HOMEWORK = 'REMOVE_ASSIGNED_HOMEWORK',
  GET_HOMEWORK_HISTORY_LIST = 'GET_HOMEWORK_HISTORY_LIST',
  GET_HOMEWORK_HISTORY_BY_ID = 'GET_HOMEWORK_HISTORY_LIST_BY_ID',
  LOAD_MORE_HOMEWORK_HISTORY_LIST = 'LOAD_MORE_HOMEWORK_HISTORY_LIST',
  GET_ASSIGNED_HOMEWORK_DETAILS = 'GET_ASSIGNED_HOMEWORK_DETAILS',
  UPDATE_HOMEWORK_DETAILS = 'UPDATE_HOMEWORK_DETAILS',
  GET_ASSIGNED_HOMEWORK_HISTORIES = 'GET_ASSIGNED_HOMEWORK_HISTORIES',
  GET_HOMEWORK_RESULT = 'GET_HOMEWORK_RESULT',
  DOWNLOAD_HOMEWORK_FILE = 'DOWNLOAD_HOMEWORK_FILE',
  GET_CLIENT_GENERAL_INFO = 'GET_CLIENT_GENERAL_INFO',
  UPDATE_CLIENT_GENERAL_INFO = 'UPDATE_CLIENT_GENERAL_INFO',
  GET_CLIENT_MEDICAL_INFORMATION = 'GET_CLIENT_MEDICAL_INFORMATION',
  UPDATE_CLIENT_MEDICAL_INFORMATION = 'UPDATE_CLIENT_MEDICAL_INFORMATION',
  RESEND_INVITATION_CLIENT = 'RESEND_INVITATION_CLIENT',
  REVOKE_INVITATION_CLIENT = 'RESEND_INVITATION_CLIENT',
  REACTIVATE_CLIENT = 'REACTIVATE_CLIENT',
  CLIENT_UPLOAD_FILE = 'CLIENT_UPLOAD_FILE',
  GET_CLIENT_FILES = 'GET_CLIENT_FILES',
  DELETE_CLIENT_FILE = 'DELETE_CLIENT_FILE',
  DOWNLOAD_CLIENT_FILE = 'DOWNLOAD_CLIENT_FILE',
  GET_AI_CONVERSATION_RESPONSES = 'GET_AI_CONVERSATION_RESPONSES',
  UPDATE_AI_CONVERSATION_RESPONSE = 'UPDATE_AI_CONVERSATION_RESPONSE',
  SUMMARISE_CONVERSATION = 'SUMMARISE_CONVERSATION',
}
