import React from 'react';
import './AIStatusBox.scss';
import { PredictedLabel } from 'src/interfaces/open-ai-interface';
import { ColorMappingClassification } from 'src/interfaces/open-ai-interface';

interface IProps {
  status: string;
}

const AIStatusBox = ({ status }: IProps) => {
  if (status === PredictedLabel.NotApplicable) {
    return null; // Don't render anything for "NotApplicable" status
  }

  const label = status as PredictedLabel;
  const mapping = ColorMappingClassification[label];

  return (
    <div
      className={`AIStatusBox__container ai`}
      style={{
        backgroundColor: mapping?.backgroundColor,
        borderColor: mapping?.backgroundColor, // Border color follows the background color
      }}
    >
      <div className="AIStatusBox__status" style={{ color: mapping?.fontColor }}>
        {status}
      </div>
    </div>
  );
};

export default AIStatusBox;
