import React from 'react';
import './InsightTab.scss';
import { Tabs } from 'antd';
import Dass from './Dass/Dass';
import RecentScore from './EmotionTracker/RecentScore';
import Statistics from './EmotionTracker/Statistics';
import { Skeleton, Space } from 'antd';
import { BaseText } from 'src/components/typography';


const { TabPane } = Tabs;

const InsightTab = () => {
  return (
    <div className="InsightsTab">
      <Tabs defaultActiveKey="recentScoreTab" animated={true} className={`TabsHeader secondary`}>
        <TabPane tab="Mood Tracker" key="recentScoreTab">
          <Statistics />
          <RecentScore />
        </TabPane>
        <TabPane tab="DASS" key="dassTab">
          <Dass />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default InsightTab;
