import { FC } from 'react';
import { useSelector } from 'react-redux';

import './NotificationItem.scss';
import { EllipseIcon, EllipseRedIcon } from 'src/assets/icons';
import Avatar from 'src/components/avatar';
import { BaseText } from 'src/components/typography';
import { TNotification } from 'src/interfaces/notification-interface';
import { TRootState } from 'src/stores';
import { getTimeToDay } from 'src/utils/common-utils';
import { ENotificationTriggerType, EUserType } from 'src/variables/enum-variables';

interface INotificationItemProps {
  notificationItemDetail: TNotification;
  onClickToNotificationItem: (notificationItem: TNotification) => Promise<void>;
}

const getMessageLabel = (content: string) => {
  try {
    const resultObj = JSON.parse(content);
    const predictedLabel = resultObj.body.predictedLabel;
    return `${predictedLabel}`;
  } catch (error) {
    //
  }
};

const NotificationItem: FC<INotificationItemProps> = ({ notificationItemDetail, onClickToNotificationItem }) => {
  const { senderProfile, senderClient, createdAt, isRead, triggerType, content } = notificationItemDetail;
  const sender = senderProfile ?? senderClient;
  const profile = useSelector((state: TRootState) => state.user.profile);

  const getNotificationText = () => {
    const senderName = `${sender?.firstName} ${sender?.lastName}`;
    switch (triggerType) {
      case ENotificationTriggerType.NEW_MESSAGE:
        return (
          <div>
            <BaseText type="button" inline textAlign="left">
              {senderName}&nbsp;
            </BaseText>
            <BaseText type="body2" inline textAlign="left">
              sent you a message
            </BaseText>
          </div>
        );
      case ENotificationTriggerType.MESSAGE_WARNING:
        return (
          <div>
          <BaseText type="button" inline textAlign="left">
            ALERT!&nbsp;
          </BaseText>
            <BaseText type="button" inline textAlign="left">
              {senderName}&nbsp;
            </BaseText>
            <BaseText type="body2" inline textAlign="left">
              {content ? `sent a message labeled as '${JSON.parse(content).predictedLabel}'.` : "sent a message."}              
            </BaseText>
          </div>
        );
      case ENotificationTriggerType.HOMEWORK_WARNING:
        return (
          <div>
          <BaseText type="button" inline textAlign="left">
            ALERT!&nbsp;
          </BaseText>
            <BaseText type="button" inline textAlign="left">
              {senderName}&nbsp;
            </BaseText>
            <BaseText type="body2" inline textAlign="left">
              {content ? `submitted a homework response labeled as '${getMessageLabel(content)}'.` : "has completed an assigned homework task."}              
            </BaseText>
          </div>
        );
      case ENotificationTriggerType.CLIENT_ACCEPT_INVITE:
      case ENotificationTriggerType.PRACTITIONER_ACCEPT_INVITE:
        return (
          <div>
            <BaseText type="button" inline textAlign="left">
              {senderName}&nbsp;
            </BaseText>
            <BaseText type="body2" inline textAlign="left">
              accepted your invitation&nbsp;
            </BaseText>
          </div>
        );
      case ENotificationTriggerType.HOMEWORK_COMPLETED:
        return (
          <div>
            <BaseText type="button" inline textAlign="left">
              {senderName}&nbsp;
            </BaseText>
            <BaseText type="body2" inline textAlign="left">
              has completed an assigned homework task
            </BaseText>
          </div>
        );
      case ENotificationTriggerType.CLIENT_REALLOCATE:
        return (
          <div>
            <BaseText type="body2" textAlign="left" inline>
              {Number(content)} client{Number(content) > 1 ? 's ' : ' '}
              {Number(content) > 1 ? 'have' : 'has'} been allocated to you by clinic {profile?.clinic?.name}
            </BaseText>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="NotificationItem" onClick={() => onClickToNotificationItem(notificationItemDetail)}>
      <div className="NotificationItem__avatar">
        <Avatar src={sender?.avatar} />
      </div>
      <div className="NotificationItem__content">
        {getNotificationText()}
        <BaseText type="x-small" inline textAlign="left" className="NotificationItem__content-time">
          {getTimeToDay(createdAt)}
        </BaseText>
      </div>
      {(!isRead && (triggerType === ENotificationTriggerType.MESSAGE_WARNING || triggerType === ENotificationTriggerType.HOMEWORK_WARNING)) ? (
        /* Render alternative content when triggerType matches */
        /* Your custom code goes here */
        <div className="NotificationItem__dot">{!isRead && <EllipseRedIcon width={10} height={10} />}</div>
        ) : (
        /* Default rendering */
        <div className="NotificationItem__dot">{!isRead && <EllipseIcon width={10} height={10} />}</div>
        )}
    </div>
  );
};

export default NotificationItem;
