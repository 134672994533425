
export enum PredictedLabel {
    NotApplicable = 'N/A',
    NonDistressing = 'Non-Distressing',
    MildDistress = 'Mild Distress',
    ModerateDistress = 'Moderate Distress',
    SevereDistress = 'Severe Distress',
    ImmediateDanger = 'Immediate Danger',
    InsensitiveDisrespectful = 'Insensitive/Disrespectful',
}

export interface ColorMapping {
    backgroundColor: string;
    fontColor: string;
  }
  
  export const ColorMappingClassification: Record<PredictedLabel, ColorMapping> = {
    [PredictedLabel.NotApplicable]: {
      backgroundColor: 'transparent',
      fontColor: '#333333', // Default font color for "NotApplicable"
    },
    [PredictedLabel.NonDistressing]: {
      backgroundColor: 'rgba(0, 255, 0, 0.1)', // Slightly more intense green
      fontColor: '#007700', // Dark green font color for good contrast
    },
    [PredictedLabel.MildDistress]: {
      backgroundColor: 'rgba(255, 153, 0, 0.1)', // More intense orange
      fontColor: '#994c00', // Dark orange font color for good contrast
    },
    [PredictedLabel.ModerateDistress]: {
      backgroundColor: 'rgba(255, 102, 0, 0.1)', // More intense orange
      fontColor: '#663300', // Dark brown font color for good contrast
    },
    [PredictedLabel.SevereDistress]: {
      backgroundColor: 'rgba(255, 51, 0, 0.1)', // More intense red
      fontColor: '#8c0000', // Dark red font color for good contrast
    },
    [PredictedLabel.ImmediateDanger]: {
      backgroundColor: 'rgba(255, 0, 0, 0.1)', // More intense red
      fontColor: '#b30000', // Darker red font color for good contrast
    },
    [PredictedLabel.InsensitiveDisrespectful]: { // New classification
        backgroundColor: 'rgba(128, 0, 128, 0.1)', // Purple background
        fontColor: '#800080', // Dark purple font color
      },
  };