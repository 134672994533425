import React from 'react';
import AngryIcon from 'src/assets/images/1F621_color.png';
import CryIcon from 'src/assets/images/1F622_color.png';
import NeutralIcon from 'src/assets/images/1F610_color.png';
import SmileIcon from 'src/assets/images/1F60A_color.png';
import HappyIcon from 'src/assets/images/1F604_color.png';
import GrimacingIcon from 'src/assets/images/1F62C_color.png';
import OverwhelmedIcon from 'src/assets/images/1F628_color.png';
import BlankIcon from 'src/assets/images/1F636_color.png';

import WorriedIcon from 'src/assets/images/1FAE3_color.png';
import ConfusedIcon from 'src/assets/images/1F928_color.png';
import NumbIcon from 'src/assets/images/1F611_color.png';
import DepressedIcon from 'src/assets/images/1F614_color.png';
import AnxiousIcon from 'src/assets/images/1F627_color.png';
import StressedIcon from 'src/assets/images/1F616_color.png';



interface IProps {
  rate: number;
  size?: number;
  onClick?: () => void; // Define an onClick handler prop
}

export enum EFEELING {
  BLANK = 0,
  ANGRY = 1,
  SAD = 2,
  NEUTRAL = 3,
  SMILE = 4,
  HAPPY = 5,
  GRIMACING_FACE = 6,
  FEARFUL_FACE = 7,
  TEARS_OF_JOY_FACE = 8,
  FACE_WITH_PEEKING_EYE = 9,
  FACE_WITH_RAISED_EYEBROW = 10,
  EXPRESIONLESS_FACE = 11,
  PENSIVE_FACE = 12,
  ANGUISHED_FACE = 13,
  CONFOUNDED_FACE = 14,
}

export const FEELING_LIST = [
  { image: BlankIcon, value: EFEELING.BLANK, label: 'Blank' },
  { image: AngryIcon, value: EFEELING.ANGRY, label: 'Angry' },
  { image: CryIcon, value: EFEELING.SAD, label: 'Sad' },
  { image: NeutralIcon, value: EFEELING.NEUTRAL, label: 'Okay' },
  { image: SmileIcon, value: EFEELING.SMILE, label: 'Good' },
  { image: HappyIcon, value: EFEELING.HAPPY, label: 'Happy' },
  { image: GrimacingIcon, value: EFEELING.GRIMACING_FACE, label: 'Anxious' },
  { image: OverwhelmedIcon, value: EFEELING.FEARFUL_FACE, label: 'Overwhelmed' },
  { image: WorriedIcon, value: EFEELING.FACE_WITH_PEEKING_EYE, label: 'Worried' },
  { image: ConfusedIcon, value: EFEELING.FACE_WITH_RAISED_EYEBROW, label: 'Confused' },
  { image: NumbIcon, value: EFEELING.EXPRESIONLESS_FACE, label: 'Numb' },
  { image: DepressedIcon, value: EFEELING.PENSIVE_FACE, label: 'Depressed' },
  { image: AnxiousIcon, value: EFEELING.ANGUISHED_FACE, label: 'Anxious' },
  { image: StressedIcon, value: EFEELING.CONFOUNDED_FACE, label: 'Stressed' },
];

const EmotionIcon = ({ rate, size = 48, onClick }: IProps) => {
  // Attach the onClick handler to the clickable div
  return (
    <div className="EmotionIcon" onClick={onClick}>
      <img src={FEELING_LIST.find((feeling) => feeling.value == rate)?.image} style={{ width: size, height: size }} />
    </div>
  );
};

export default EmotionIcon;
